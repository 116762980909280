import api from '@/libs/axios'

export default {
  async getReport(params) {
    let response = await api.get('/admin/learning-feedbacks/report', {
      params,
    })
    return response
  },
  async getList(params) {
    let response = await api.get('/admin/learning-feedbacks', {
      params,
    })
    return response
  },
  // async get(params) {
  //   let response = await api.get('/admin/learning-feedbacks/detail', {
  //     params,
  //   })
  //   return response.data.data || null
  // },

  // async create(payload) {
  //   const response = await api.post('/admin/learning-feedbacks', payload)
  //   return response || null
  // },

  // async update(payload) {
  //   const response = await api.put('/admin/learning-feedbacks', payload)
  //   return response || null
  // },

  // async delete(payload) {
  //   const response = await api.delete('/admin/learning-feedbacks', {
  //     data: payload,
  //   })
  //   return response
  // },
}
